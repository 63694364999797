@import "../variables";

.editor {
    .main-targeting {
        .main-targeting-inner {
            flex: 1;

            .subpage {
                flex: 1;
                overflow-y: scroll;
                max-height: calc(100vh - 122px);

                .page-inner {
                    margin: 25px 15px;

                    .card {
                        margin: 0 0 35px 0;
                        background-color: #f7fafc;
                        border-radius: 4px;
                        box-shadow: 0 7px 14px 0 rgba(59, 65, 94, 0.1), 0 3px 6px 0 rgba(0, 0, 0, 0.07);
                        border: 1px solid rgba(0, 0, 0, 0.125);

                        p.card-heading {
                            color: #515355;
                            text-align: left;
                            padding: 12px 15px 12px 15px;
                            font-weight: 500;
                            margin: 0;
                            background-color: #eff2f4;
                            border-bottom: 1px solid #d1d4d5;

                        }

                        .card-body {
                            padding: 15px 15px 0 15px;


                            .toggle-switch-row {
                                display: flex;
                                justify-content: space-between;
                                margin-bottom: 15px;

                                p {
                                    margin: 0;
                                }
                            }

                            .select-control {
                                margin: 10px 0;

                                label {
                                    margin: 0;
                                }

                                .select-control-inner {
                                    position: relative;

                                    i {
                                        position: absolute;
                                        top: 5px;
                                        right: 10px;
                                        pointer-events: none;
                                        color: #8199a9;
                                    }

                                    select {
                                        outline: 0;
                                        font-size: 13px;
                                        color: rgba(6, 56, 85, 0.8);
                                        background-color: rgb(249, 250, 252);
                                        line-height: 16px;
                                        -webkit-appearance: none;
                                        display: block;
                                        width: 100%;
                                        border: 1px solid rgb(215, 215, 215);
                                        border-image: initial;
                                        transition: border-color 300ms ease 0s;
                                        padding: 7px 24px 7px 16px;
                                        border-radius: 3px;
                                    }
                                }
                            }

                            .slider-control {
                                margin: 10px 0;

                                label {
                                    margin: 0;
                                }

                                .slider-option {
                                    display: flex;
                                    align-items: center;

                                    .value-input {
                                        width: 50px;
                                        margin-left: 15px;

                                    }

                                    p {
                                        margin: 0 0 0 5px;
                                    }
                                }
                            }

                            .color-control {
                                margin: 10px 0;

                                label {
                                    margin: 0;
                                    display: block;
                                }

                                input {
                                    width: 30px;
                                    height: 30px;
                                    padding: 0;
                                    border-radius: 4px;
                                }
                            }

                            .control-btn-group {
                                margin: 10px 0;

                                label {
                                    margin: 0;
                                    display: block;
                                }

                                .btn-toolbar {
                                    display: inline-block;
                                    border: 1px solid #d7d7d7;
                                    border-radius: 4px;

                                    .btn {
                                        display: flex;
                                        border: 0;
                                        box-shadow: none !important;
                                        background-color: #f7fafc;

                                        i {
                                            color: #c0ccda;
                                        }
                                    }

                                    .btn.active {
                                        i {
                                            color: #3899ec;
                                        }
                                    }
                                }

                            }

                            .image-upload-control {

                                .upload-btn-wrapper {
                                    position: relative;
                                    overflow: hidden;
                                    display: inline-block;
                                    cursor: pointer;

                                    input[type=file] {
                                        font-size: 100px;
                                        position: absolute;
                                        left: 0;
                                        top: 0;
                                        opacity: 0;
                                        cursor: pointer;
                                    }
                                }

                                .upload-btn-wrapper:hover {
                                    .btn-primary {
                                        background-color: #4eb7f5;
                                        border-color: #4eb7f5;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}