@import "../variables";

.page-analytics-campaign {

    .chart-options-container {
        display: flex;
        align-items: center;
        margin: 15px 0 15px 0;


        p.title {
            margin: 0 15px 0 0;
            font-family: $helvetica-500;
        }
    }

    .card-linechart {
        position: relative;

        .insufficient-data-container {
            position: absolute;
            margin: auto;
            top: 0; left: 0; bottom: 0; right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .card-location {

        .legend-container {
            min-height: 24px;

            p {
                margin: 0;
                font-weight: 500;
            }
        }
    }
}