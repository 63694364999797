@import "../../stylesheets/variables";

.image-library {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 1030;
    display: flex;

    .close-btn-container {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;

        i {
            color: #5a5a5a;
            font-size: 32px;
        }
    }

    .close-btn-container:hover {
        i {
            color: #000;
        }
    }

    .sidebar {
        width: 200px;
        min-width: 200px;
        border-right: 1px solid #d0d0d0;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 25px 0;
        background-color: #f3f3f3;

        ul {
            list-style: none;
            padding: 0;
            width: 100%;

            li {
                transition: all 0.35s;
                display: block;
                color: #4d4d4d;
                font-size: 14px;
                font-family: $helvetica-600;
                padding: 12px 25px;
                text-decoration: none;
                line-height: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                cursor: pointer;
            }

            li:hover {
                background-color: #cfe7fc;
            }

            li.active {
                background-color: #cfe7fc;
                color: #3899ec;

            }

            li.divider {
                border-bottom: 1px solid #434567;
                margin: 15px 25px;
            }
        }
    }

    .image-library-page {
        padding: 25px;
        flex: 1;

        h1 {
            margin: 0 0 25px 0;
            display: block;
        }
    }

    .upload-image-page {
        .image-upload-control {
            margin: 25px 0 0 0;

            .upload-btn-wrapper {
                position: relative;
                overflow: hidden;
                display: inline-block;
                cursor: pointer;

                input[type=file] {
                    font-size: 100px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    opacity: 0;
                    cursor: pointer;
                }
            }

            .upload-btn-wrapper:hover {
                .btn-primary {
                    background-color: #4eb7f5;
                    border-color: #4eb7f5;
                }
            }
        }
    }

    .library-page {
        display: flex;
        flex-direction: column;

        .images-container {
            display: grid;
            //grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 15px;
            flex: 1;
            overflow: scroll;

            .image-container {
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                border: 1px solid #d0d0d0;
                border-radius: 4px;
                height: 250px;
                display: flex;
                justify-content: center;
                align-items: center;

                .btn-container {
                    display: none;
                    flex-direction: column;

                    .btn {
                        margin: 5px 0;
                    }
                }

            }

            .image-container:hover {
                border-color: #4eb7f5;

                .btn-container {
                    display: flex;
                }
            }
        }
    }

    .stock-photos-page {
        display: flex;
        flex-direction: column;

        h1 {
            .provider {
                font-size: 1rem;
                font-family: $helvetica-400;
                margin-left: 10px;
                color: #666;
            }
        }

        .query-container {

        }

        .fetching-images-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
        }

        .images-container {
            flex: 1;
            overflow: scroll;

            .images-container-inner {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr;
                grid-gap: 15px;

                .image-container {

                    .image-container-inner {
                        border: 1px solid #d0d0d0;
                        border-radius: 4px;
                        cursor: pointer;
                        background-position: center;
                        background-size: contain;
                        background-repeat: no-repeat;
                        height: 250px;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .btn-container {
                            display: none;
                            flex-direction: column;

                            .btn {
                                margin: 5px 0;
                            }
                        }
                    }

                    .image-container-inner:hover {
                        border-color: #4eb7f5;

                        .btn-container {
                            display: flex;
                        }
                    }

                    .info-container {
                        text-align: center;

                        p {
                            font-size: 14px;
                            margin: 5px 0 0 0;
                            color: #aaa;
                            line-height: 1;
                        }

                        a {
                            font-size: 10px;
                        }
                    }
                }
            }

            .page-nav-container {
                margin: 15px 0 15px 0;

                display: flex;
                justify-content: center;

                .btn {
                    margin: 0 10px;
                }
            }
        }
    }
}

.add-to-library-modal {
    .modal-inner {
        display: flex;

        .img-container {
            img {
                max-width: 250px;
            }
        }

        .info-container {
            margin-left: 25px;
            p {
                margin-bottom: 5px;
            }
        }
    }
}