.editor-controls {
    display: flex;
    //justify-content: space-between;
    padding: 0 15px;
    height: 50px;
    margin: 0;

    label {
        color: #808080;
        margin: 0 10px 0 0;
        font-weight: bold;
        font-size: 12px;
    }

    .zoom-factor-container {
        flex: 1;
        display: flex;
        justify-content: flex-end;

        .zoom-factor-container-inner {
            width: 250px;
            display: flex;
            align-items: center;

            .rc-slider {
                .rc-slider-rail {
                    background-color: #d7d7d7;
                }
            }
        }
    }
}