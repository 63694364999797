.sidebar-screen-settings {
    .image-container {
        display: flex;
        align-items: flex-end;

        .img-container {
            width: 75px;
            height: 100px;
            margin: 0 10px 0 0;
            border-radius: 4px;
            border: 1px solid #ced4da;
            background-size: cover;
            background-position: center;
        }

        .btn {
            svg {
                margin-right: 5px;
            }
        }

    }

    .btn-delete-container {
        display: flex;
        justify-content: center;

        .btn {
            svg {
                margin-right: 5px;
            }
        }
    }
}