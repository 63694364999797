@import "../../stylesheets/variables";

.sidebar {
    padding: 25px 0;
    position: relative;
    background-color: #f3f3f3;
    width: 220px;
    flex: 0 0 220px;
    border-right: 1px solid #dadada;

    .main-menu-link-container {
        a {
            display: block;
            color: #454a54;
            font-size: 14px;
            font-weight: 600;
            padding: 12px 25px;
            text-decoration: none;
            line-height: 1;

            .arrow-container {
                margin-right: 8px;

                svg {
                    transform: scale(2);
                    transition: transform 0.5s;
                    width: 14px;
                    height: 14px;
                    fill: #454a54;
                    padding-top: 1px;
                }
            }
        }

        a:hover {
            .arrow-container {
                svg {
                    transform: translateX(-5px) scale(2);
                }
            }
        }
    }

    ul {
        list-style: none;
        padding: 0;

        li {
            transition: all 0.35s;
            border-left: 5px solid transparent;

            a {
                display: block;
                color: #454a54;
                font-size: 14px;
                font-weight: 600;
                padding: 12px 25px;
                text-decoration: none;
                line-height: 1;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        li:hover {
            background-color: #e9e9e9;
        }

        li.active {
            border-left-color: #f76d6d;

            a {
                color: #f76d6d;
            }
        }

        li.divider {
            border-bottom: 1px solid #dadada;
            margin: 15px 25px;
        }
    }

    .sidebar-section {
        padding: 12px 15px;

        p.header {
            font-family: $helvetica-600;
            font-size: 12px;
            line-height: 18px;
            color: #7a92a5;
            text-transform: none;
            cursor: default;
            padding: 0 10px;
        }

        .menu-list {

            .menu-entry {
                transition: all 0.35s;

                p {
                    display: block;
                    color: #fff;
                    font-size: 14px;
                    font-family: $helvetica-600;
                    padding: 12px 10px;
                    text-decoration: none;
                    line-height: 1;
                }
            }

            .menu-entry:hover {
                background-color: #353950;
            }

            .menu-entry.active {
                background-color: #3a3f57;

                p {
                    color: #3899ec;
                }
            }

            .menu-entry.divider {
                border-bottom: 1px solid #dadada;
                margin: 15px 25px;
            }
        }
    }

    .upgrade-container {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 25px;
        display: flex;
        justify-content: center;
    }
}