.page {
	display: flex;
	flex-direction: column;
	flex: 1;
	min-height: 0;
	overflow: scroll;



	.page-inner {
		padding: 0 25px;
		flex: 1;
		//min-height: 100vh;
		margin-bottom: 250px;

		h1 {
			margin: 25px 0 15px 0;
		}

		.subnav {
			box-shadow: 0 7px 14px 0 rgba(59, 65, 94, .1), 0 3px 6px 0 rgba(0, 0, 0, .07);
			margin-bottom: 15px;
			background-color: #f7fafc;
			border-radius: 4px;

			.nav-link {
				margin: 0;
				padding: 15px 25px;
				border-bottom: 2px solid #f7fafc;
				color: #6a7386;
				font-size: 1rem;
			}

			.nav-link.active {
				background-color: #fff;
				color: #586ada;
				border-color: #586ada;
			}
		}

		p.subheading {
			color: #32536a;
		}

		.header-container {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.text-container {

			}

			.btn-container {
				.btn {
					margin-left: 10px;
				}
			}
		}
	}
}