#footer-top {
    color: #8e8f90;
    background-color: #403e3f;
    padding: 25px 0;
    min-height: 175px;
    display: flex;
    align-items: center;

    .logo {
        width: 100px;
        height: auto;
    }

    .help-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .need-help-text {
            font-size: 1.5rem;
            color: #fff;
            margin: 0 15px 0 0;
        }
    }
}


#footer-bottom {
    background-color: #2f2e2f;
    display: flex;
    min-height: 50px;
    align-items: center;
    padding: 0;

    .footer-col {
        display: flex;
        align-items: center;

        .footer-list {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                display: inline-block;

                a {
                    color: #767576;
                    transition: all 0.35s;
                }

                a:hover {
                    color: #fff;
                    text-decoration: none;
                }
            }
        }

        p {
            color: #767576;
        }
    }

    .terms-col {
        .footer-list {
            li {
                margin-right: 15px;
            }
        }
    }

    .made-col {
        justify-content: center;

        p.text-made {
            margin: 0;
        }
    }

    .social-col {
        justify-content: flex-end;

        .social-info {
            display: flex;

            p {
                margin: 0;
            }

            a {
                i {
                    margin-left: 10px;
                    color: #979697;
                    transition: all 0.35s;
                }
            }

            a:hover {
                i {
                    color: #fff;
                }
            }
        }
    }


}