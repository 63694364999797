.gradient-library {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    height: 250px;
    overflow-y: scroll;

    .gradient {
        height: 50px;
        cursor: pointer;
        border-radius: 4px;
    }

    .gradient:hover {
        border: 1px solid #007bff;
    }

}