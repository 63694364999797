@import "../variables";

.editor {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: calc(100vh - 64px);

    .toolbar-top {
        flex: 0 0 56px;
        display: flex;
        background-color: #fff;
        z-index: 1010;
        border-bottom: 1px solid #eff0f1;

        .tools-container {
            display: flex;
            justify-content: space-between;
            height: 100%;

            .tool-group {
                display: flex;
                align-items: center;
                border-left: 1px solid #e6ebee;
                margin: 5px 0;
                padding: 0 15px;

                .tool {

                }

                .tool-campaign-active-switch {
                    display: flex;
                    align-items: center;

                    p {
                        margin: 0 10px 0 0;
                        line-height: 1;
                    }
                }

                .tool-display-grid-switch {
                    display: flex;
                    align-items: center;

                    p {
                        margin: 0 10px 0 0;
                        line-height: 1;
                        white-space: nowrap;
                    }
                }

                .tool-campaign-name {
                    display: flex;
                    align-items: center;
                    width: 100%;

                    input.form-control {

                    }
                }
            }

            .tool-group-add-screen {
                flex: 0 0 165px;

                .btn {
                    svg {
                        margin-right: 5px;
                    }
                }
            }

            .tool-group-select-language {
                flex: 0 0 250px;
                display: flex;
                align-items: center;

                .tool {
                    width: 100%;

                    .select-control {
                        width: 100%;

                        .select-control-inner {
                            position: relative;

                            i {
                                position: absolute;
                                top: 7px;
                                right: 10px;
                                pointer-events: none;
                                color: #8199a9;
                            }

                            select {
                                outline: 0;
                                font-size: 1rem;
                                color: rgba(6, 56, 85, 0.8);
                                background-color: #fff;
                                -webkit-appearance: none;
                                display: block;
                                width: 100%;
                                border: 1px solid #ced4da;
                                border-image: initial;
                                transition: border-color 300ms ease 0s;
                                padding: 7px 24px 7px 16px;
                                border-radius: .25rem;
                                height: calc(1.5em + .75rem + 2px);
                            }
                        }
                    }
                }
            }

            .tool-group-name {
                width: 100%;
            }

            .tool-group-save {
                .tool {
                    display: flex;
                    align-items: center;

                    .save-status {
                        margin: 0 10px 0 0;
                        p {
                            margin: 0;
                            color: #b7b7b7;
                            font-size: 0.8rem;
                            line-height: 1;
                            text-align: center;
                        }
                    }
                }
            }

            .tool-btn-group {
                display: flex;
                align-items: center;
                border-left: 1px solid #e6ebee;
                margin: 5px 0;
                padding: 0 15px;

                .btn-tool {
                    i {
                        color: #c0ccd9;
                    }
                }

                .btn-tool.active {
                    i {
                        color: #449fed;
                    }
                }
            }

            .tool-btn-group-display {
                border: 0;
            }
        }

        .tools-container-left {
            justify-content: flex-start;
            flex: 1;
        }

        .tools-container-right {
            display: flex;
            justify-content: flex-end;
        }
    }

    .main {
        flex: 1;
        display: flex;
        flex-direction: row;
        overflow: hidden;

        .toolbar-left {
            flex: 0 0 100px;
            background-color: #fff;
            z-index: 100;
            border-right: 1px solid #eff0f1;

            .tool-link {
                display: flex;
                width: 100%;
                height: 80px;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                position: relative;
                text-decoration: none;
                color: #000;
                opacity: 0.50;
                background: none;
                border: none;
                transition: all 0.35s;

                i {
                    //font-size: 30px;
                }

                p {
                    margin: 5px 0 0 0;
                    font-size: 14px;
                    line-height: 1;
                }

                .badge {
                    width: 10px;
                    height: 10px;
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    border-radius: 50%;
                    background-color: #c6262c;
                    display: block;
                }
            }

            .tool-link:hover {
                opacity: 1;
            }

            .tool-link.active {
                color: #449fed;
                opacity: 1;
            }
        }
    }

    .main-edit {

        .sidebars-container {
            flex: 0 0 300px;
            max-width: 360px;
            background-color: #fff;
            z-index: 50;

            .editor-sidebar {
                height: 100%;
                max-height: calc(100vh - 57px);
                overflow-y: scroll;
                font-size: 0.9rem;

                .sidebar-header {
                    margin: 15px 0 0 0;

                    p {
                        font-family: $helvetica-600;
                        font-size: 1.1rem;
                        text-align: center;
                    }
                }

                .sidebar-section {
                    margin: 0 10px 35px 10px;
                    background-color: #fff;
                    border-radius: 4px;
                    border: 1px solid rgba(0, 0, 0, 0.125);

                    p.heading {
                        font-family: $helvetica-600;
                        color: #000;
                        font-size: 13px;
                        text-align: center;
                        padding: 5px 0;
                        margin: 0;
                    }

                    .sidebar-section-inner {
                        padding: 5px 15px;

                        .control-label {
                            margin: 10px 0 0 0;
                        }

                        .toggle-switch-row {
                            display: flex;
                            justify-content: space-between;
                            margin-bottom: 15px;

                            p {
                                margin: 0;
                            }
                        }

                        form {
                            .form-group {
                                label {
                                    font-weight: normal;
                                    margin: 0;
                                }

                                input.form-control {
                                    font-size: 13px;
                                    padding: 7px 24px 7px 16px;
                                    line-height: 16px;
                                    height: auto;
                                }
                            }
                        }

                        .input-control {
                            width: 100%;
                            border-radius: 3px;
                            font-size: 13px;
                            height: 32px;
                            padding-left: 16px;
                            padding-right: 16px;

                        }

                        .select-control {
                            margin: 10px 0;

                            label {
                                margin: 0;
                            }

                            .select-control-inner {
                                position: relative;

                                i {
                                    position: absolute;
                                    top: 5px;
                                    right: 10px;
                                    pointer-events: none;
                                    color: #8199a9;
                                }

                                select {
                                    outline: 0;
                                    font-size: 13px;
                                    color: rgba(6, 56, 85, 0.8);
                                    background-color: #fff;
                                    line-height: 16px;
                                    -webkit-appearance: none;
                                    display: block;
                                    width: 100%;
                                    border: 1px solid rgb(215, 215, 215);
                                    border-image: initial;
                                    transition: border-color 300ms ease 0s;
                                    padding: 7px 24px 7px 16px;
                                    border-radius: 3px;
                                }
                            }
                        }

                        .slider-control {
                            margin: 10px 0;

                            label {
                                margin: 0;
                            }

                            .slider-option {
                                display: flex;
                                align-items: center;

                                .value-input {
                                    width: 50px;
                                    margin-left: 15px;

                                }

                                p {
                                    margin: 0 0 0 5px;
                                }
                            }
                        }


                        .slider-control-with-units {
                            margin: 10px 0;

                            label {
                                margin: 0;
                            }

                            .slider-option {
                                display: flex;
                                align-items: center;

                                .value-input {
                                    width: 55px;
                                    margin-left: 15px;

                                }

                                p {
                                    margin: 0 0 0 5px;
                                }
                            }

                            .unit-select {
                                position: relative;
                                margin: 0 0 0 5px;

                                i {
                                    position: absolute;
                                    top: 2px;
                                    right: 0px;
                                    pointer-events: none;
                                    color: #8199a9;
                                }

                                select {
                                    outline: 0;
                                    font-size: 13px;
                                    color: rgba(6, 56, 85, 0.8);
                                    background-color: rgb(249, 250, 252);
                                    line-height: 16px;
                                    -webkit-appearance: none;
                                    display: block;
                                    width: 100%;
                                    border: 1px solid rgb(215, 215, 215);
                                    border-image: initial;
                                    transition: border-color 300ms ease 0s;
                                    padding: 5px 8px 5px 8px;
                                    border-radius: 0;
                                    height: 27px;
                                    min-width: 50px;
                                }
                            }
                        }

                        .color-control {
                            margin: 10px 0;

                            label {
                                margin: 0;
                                display: block;
                            }

                            input {
                                width: 30px;
                                height: 30px;
                                padding: 0;
                                border-radius: 4px;
                            }
                        }

                        .control-btn-group {
                            margin: 10px 0;

                            label {
                                margin: 0;
                                display: block;
                            }

                            .btn-toolbar {
                                display: inline-block;
                                border: 1px solid #d7d7d7;
                                border-radius: 4px;
                                //width: 100%;

                                .btn-group {
                                    display: flex;
                                    //justify-content: space-between;
                                    //width: 100%;

                                    .btn {
                                        display: flex;
                                        border: 0;
                                        box-shadow: none !important;
                                        background-color: #fff;
                                        padding: 0 15px;
                                        min-width: 0;

                                        i {
                                            color: #c0ccda;
                                        }
                                    }

                                    .btn.active {
                                        i {
                                            color: #449fed;
                                        }
                                    }
                                }
                            }
                        }

                        .image-upload-control {

                            .upload-btn-wrapper {
                                position: relative;
                                overflow: hidden;
                                display: inline-block;
                                cursor: pointer;

                                input[type=file] {
                                    font-size: 100px;
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    opacity: 0;
                                    cursor: pointer;
                                }
                            }

                            .upload-btn-wrapper:hover {
                                .btn-primary {
                                    background-color: #4eb7f5;
                                    border-color: #4eb7f5;
                                }
                            }
                        }

                        .help-text-container {
                            display: flex;
                            align-items: center;
                            margin: 5px 0;

                            .material-icons {
                                color: #08aaed;
                                font-size: 14px;
                                margin-right: 5px;
                            }

                            a {
                                //color: #08aaed;
                                font-size: 12px;
                            }
                        }
                    }
                }

                .sidebar-subsection {
                    p.subsection-heading {
                        font-family: $helvetica-600;
                        font-size: 13px;
                        text-align: center;
                        margin: 25px 0 5px 0;
                    }
                }
            }
        }

        .main-inner {
            display: flex;
            flex-direction: column;
            flex: 1;
            overflow: hidden;
            background-color: #efefef;
            background-size: 20px 20px;
            background-position: 0px 0px, 10px 10px;

            .no-screen-container {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;

                .btn-container {
                    background-color: #fff;
                    border: 1px solid #d7d7d7;
                    border-radius: 8px;
                    padding: 50px;

                    .btn {
                        //padding: 25px;
                    }
                }
            }


            .screens-container-outer {
                margin: 0 0 15px 0;
                flex: 1;
                overflow: scroll;

                .screens-container {
                    display: grid;
                    flex: 1;

                    .screen-container {

                        margin: 0 10px;
                        text-align: center;
                        font-weight: bold;

                        .screen-container-inner {
                            border: 2px solid #d7d7d7;
                            border-radius: 8px;
                            padding: 10px;
                            display: flex;
                            flex-direction: column;
                            cursor: pointer;

                            .screen-item-container {
                                .heading-container {

                                    p {
                                        color: #808080;
                                        font-size: 14px;
                                        margin: 0 0 5px 0;

                                        svg {
                                            margin-left: 5px;
                                        }
                                    }
                                }

                                .screen-item {
                                    width: 100%;
                                    //border-radius: 8px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    position: relative;

                                    canvas {
                                        width: 100%;
                                        border-radius: 8px;
                                    }
                                }

                                .info-container {
                                    height: 40px;

                                    p {
                                        font-size: 12px;
                                        color: #808080;
                                        margin: 0;
                                        font-weight: 400;
                                    }
                                }
                            }

                            .screen-item-container:not(:last-of-type) {
                                margin-bottom: 25px;
                            }
                        }

                        .screen-container-inner:hover {
                            border: 2px solid #007bff;

                        }

                        .screen-container-inner.active {
                            border: 2px solid #007bff;
                        }
                    }
                }
            }
        }
    }
}