.page-header {
    display: flex;
    background-color: #fff;

    .nav-container {
        flex: 1;

        .nav {

            .nav-link {
                color: #808080;
                padding: 1rem 1.5rem;
                transition: all 0.35s;
                font-weight: 500;
            }

            .nav-link:hover {
                color: #000000;
            }

            .nav-link.active {
                color: #3899eb;
                background-color: #e3e8ee;
            }

            .nav-link.active:hover {
                color: #3899eb;
            }
        }
    }

    .tools-container {
        display: flex;
        align-items: center;

        .tool-group {
            border-left: 1px solid #e6ebee;
            margin: 5px 0;
            padding: 0 15px;
        }
    }
}