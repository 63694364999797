.screenshot-language-block {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;

    .screenshot-size-block {
        background-color: #efefef;
        border-radius: 6px;
        padding: 10px;

        .header {
            color: #808080;
            text-align: center;
            font-weight: 500;
            margin: 0 0 5px 0;
        }

        .block-container {
            display: grid;
            grid-template-columns: 1fr 2fr;
            grid-gap: 10px;


            .preview-container {
                //width: 100%;
                height: 80px;

                .screenshot-container {
                    width: 100%;
                    height: 100%;
                    border: 1px solid #808080;
                    border-radius: 4px;
                }

                .screenshot-not-available {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    i {
                        color: #808080;
                    }
                }

                .screenshot-available {
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;

                }
            }

            .info-container {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;

                .description-container {
                    p {
                        color: #808080;
                        font-size: 12px;
                        line-height: 1;
                    }
                }

                .btn-container {
                    display: grid;
                    grid-template-columns: 1fr 1fr;

                    .image-upload-control {

                        .upload-btn-wrapper {
                            position: relative;
                            overflow: hidden;
                            display: inline-block;
                            cursor: pointer;


                            input[type=file] {
                                font-size: 100px;
                                position: absolute;
                                left: 0;
                                top: 0;
                                opacity: 0;
                                cursor: pointer;
                            }
                        }

                        .upload-btn-wrapper:hover {
                            .btn-primary {

                                background-color: #4eb7f5;
                                border-color: #4eb7f5;
                            }
                        }
                    }
                }
            }
        }
    }
}