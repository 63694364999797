.no-projects-card {
    flex: 1;
    margin: 0;
    background-color: #fff;

    .heading-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 0 35px 0;
        text-align: center;
    }

    .img-container {
        flex: 1;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin: 45px;
        height: 200px;
    }

    .btn-container {
        display: flex;
        justify-content: center;
    }

}