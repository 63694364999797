@import "../variables";

.page-not-found {
    background-color: #fff;

    .hero-container {
        margin: 100px 0 0 50px;

        h1 {
            font-size: 45px;
            line-height: 50px;
        }

        h2 {
            margin-top: 22px;
            font-size: 22px;
            line-height: 26px;
            font-family: $helvetica-400;
        }

        ul {
            margin-top: 53px;
            list-style: none;
            padding: 0;

            li {
                a {
                    position: relative;
                    font-size: 18px;
                    line-height: 30px;
                    font-family: $helvetica-500;
                }
            }
        }
    }

    .img-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 50px;

        svg {
            width: 100%;
            max-width: 400px;
        }
    }
}