.campaign-not-valid-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 450px;
        margin: 25px 0;
    }

    p {
        text-align: center;
    }
}