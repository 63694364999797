.sidebar-grid-block-controls {

    .back-container {
        margin: 0 0 15px 0;
        color: #808080;
        display: flex;
        align-items: center;

        span {
            cursor: pointer;
        }

        span:hover {
            text-decoration: underline;
        }
    }

    .image-btn-container {
        display: flex;
        justify-content: center;
        margin: 10px 0;

        .btn {
            margin: 0 5px;
        }
    }
}