
.btn {
    outline: 0;
    border-radius: 22px;
    cursor: pointer;
    padding: 0 26px;
    height: 36px;
    min-width: 90px;
    font-size: 14px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 1 !important;


    .fa-spinner {
        margin-left: 5px;
    }

    .btn-inner {
        display: flex;
        align-items: center;
        height: 100%;

        i {
            margin-right: 5px;
            font-size: 16px;
        }
    }

    svg {
        margin-right: 5px;
    }
}

.btn.btn-successNew {
    color: #fff;
    background-color: #3bd458;
}

.btn.btn-successNew:hover {
    color: #fff;
    background-color: #2bce4b;
}

// primary

.btn.btn-primaryNew {
    color: #fff;
    background-color: #05abed;
}

.btn.btn-primaryNew:hover {
    color: #fff;
    background-color: #009dd9;
}

.btn.btn-primaryOutlineNew {
    color: #05abed;
    border: 1px solid #05abed;
    background-color: transparent;
}

.btn.btn-primaryOutlineNew:hover {
    color: #fff;
    border: 1px solid #05abed;
    background-color: #009dd9;
}

// secondary
.btn.btn-secondaryNew {
    color: #757575;
    background-color: #eaecf0;
}

.btn.btn-secondaryNew:hover {
    color: #757575;
    background-color: #dde2e8;
}

.btn-small {
    height: 26px !important;
    padding: 0 !important;
    font-size: 13px !important;
    min-width: 60px !important;
}

.btn:focus {
    outline:0;
    box-shadow: none;
}

.btn-link {
    background: none;
    border: none;
    cursor: pointer;
}

.btn-large {
    padding: 10px 20px;
}

.btn-primary, .btn-primary.disabled {
    color: #fff;
    background-color: #3899ec;
    border: 1px solid #3899ec;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
    color: #fff;
    background-color: #4eb7f5 !important;
    border-color: #4eb7f5 !important;
}

.btn-primary-outline {
    border: 1px solid #3899ec;
    color: #3899ec;
    background-color: transparent;
}

.btn-primary-outline:hover {
    border: 1px solid #3899ec;
    color: #fff;
    background-color: #3899ec;
}

.btn-secondary {
    color: #6d6d6d;
    background-color: #fff;
    border: 1px solid #c0ccda;
}

.btn-secondary:hover,
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active {
    color: #6d6d6d;
    background-color: #fff;
}

.btn-secondary:disabled {
    color: #4e566d;
    background: #fff;
}

.btn-purple {
    color: #fff;
    background-color: #aa4dc7;
    border-color: #aa4dc7;
}

.btn-purple:hover {
    color: #fff;
    background-color: #cc68ed;
    border-color: #cc68ed;
}

.btn-purple-outline {
    color: #aa4dc7;
    background-color: transparent;
    border-color: #aa4dc7;
}

.btn-purple-outline:hover {
    color: #fff;
    background-color: #aa4dc7;
    border-color: #aa4dc7;
}



.button-spinner {
    margin-left: 5px;
}

.btn-icon {
    outline: 0;
    border: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
        font-size: 20px;
        color: #515355;
        transition: all 0.25s;
    }

    .caption {
        margin-left: 5px;
        transition: all 0.25s;
        color: #515355;
    }
}

.btn-icon:hover {
    transition: all 0.25s;

    i, .caption {
        color: #01bcd4;
    }
}

.btn-icon:focus {
    outline:0;
    box-shadow: none;
}


form {
    .form-group {
        label {
            font-weight: 600;
        }

        .form-text-error {
            color: #9d2146;
        }
    }
}