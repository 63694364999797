@import "../../../stylesheets/variables";


.thank-you-container {
    padding: 0 0 25px 0;

    .thank-you-banner {
        display: flex;
        background-color: #70bef1;
        color: #fff;
        flex-direction: column;
        align-items: center;
        min-height: 175px;
        justify-content: center;

        h1 {
            margin: 0 0 10px 0;
            font-family: $helvetica-400;
            //font-size: 30px;
        }

        p {
            margin: 0;
            font-family: $helvetica-400;
            font-size: 16px;
        }
    }

    .img-container {
        display: flex;
        justify-content: center;
        margin: 45px 0;

        img {
            width: 300px;
            margin-left: -50px;
        }
    }

    .action-container {
        display: flex;
        flex-direction: column;
        align-items: center;

    }


}