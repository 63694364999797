.checkbox-list {
    .container {
        display: block;
        position: relative;
        padding-left: 30px;
        margin-bottom: 8px;
        cursor: pointer;
        user-select: none;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        input:checked ~ .checkmark {
            background-color: #2196F3;
        }

        input:checked ~ .checkmark:after {
            display: block;
        }

        .checkmark {
            position: absolute;
            top: 2px;
            left: 0;
            height: 17px;
            width: 17px;
            background-color: #eee;
        }

        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
            left: 6px;
            top: 3px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 3px 3px 0;
            transform: rotate(45deg);
        }
    }
}

.form-group {
    .checkbox-list {
        padding-top: calc(.375rem + 1px);
        padding-bottom: calc(.375rem + 1px);
    }
}