.page-settings-integrations {

    .integrations {
        .integration {
            border: 1px solid #dfdfdf;
            padding: 25px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .logo {
                height: 100px;
                width: auto;
            }


            p {
                text-align: center;
            }

            .btn-container {
                text-align: center;
            }
        }
    }

}