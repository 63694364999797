.toggle-switch {
    label {
        margin: 0;
    }

    .lbl {
        position: relative;
        display: block;
        height: 20px;
        width: 44px;
        background: #898989;
        border-radius: 100px;
        cursor: pointer;
        transition: all 0.3s ease;
    }

    .lbl:after {
        position: absolute;
        left: -2px;
        top: -3px;
        display: block;
        width: 26px;
        height: 26px;
        border-radius: 100px;
        background: #f1f3f4;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
        content: '';
        transition: all 0.3s ease;
    }
    .cbx:checked ~ label {
        background: #7fccf7;
    }
    .cbx:checked ~ label:after {
        left: 20px;
        background: #449fed;
    }
    .cbx:disabled ~ label {
        background: #7fccf7;
        pointer-events: none;
    }
    .cbx:disabled ~ label:after {
        background: #7fccf7;
    }
}