@import "../variables";

.page-campaigns-new {

    .filter-container {
        display: flex;
        align-items: center;
        margin: 0 0 25px 0;

        p {
            margin: 0 10px 0 0;
            line-height: 1;
        }

        .dropdown-toggle {
            background-color: #fff;
            border: 1px solid #c1e4fe;
            color: #162c3c;
        }

        .dropdown-toggle:hover,
        .dropdown-toggle:focus {
            background-color: #eaf7ff !important;
            border: 1px solid #c1e4fe !important;
        }
    }

    .templates-container {

        .template-container {
            //padding: 0 15px;

            .preview-container {
                margin-bottom: 10px;
                transition: all 0.35s;
                display: block;
                cursor: pointer;

                .preview-img {
                    width: 100%;
                    height: auto;
                }
            }

            .preview-container:hover {
                text-decoration: none;

                .preview-img {
                    box-shadow: 0 6px 12px 0 rgba(207, 212, 226, .5)
                }
            }

            .description-container {
                margin: 0 0 45px 0;

                p.name {
                    color: #515355;
                    font-size: 1.2rem;
                    font-family: $helvetica-600;
                    margin: 0;
                }

                p.description {
                    margin: 0 0 10px 0;
                }
            }
        }
    }

}