.page-settings-billing {

    .card-plan-info {
        .plans-container {
            .plan-container {
                border: 1px solid #c0ccda;
                padding: 15px;
                margin: 0 0 25px 0;
                transition: all 0.35s;
                cursor: pointer;

                p.plan-name {
                    color: #515355;
                    font-weight: 600;
                }
            }

            .plan-container.active {
                border: 2px solid #3899eb;
            }

            .plan-container:hover {
                border-color: #3899eb;
            }
        }
    }

    .card-payment-details {

        .payment-form-container {

            label {
                font-family: $helvetica-300;
                font-size: 14px;
                margin: 0;
            }

            input, select, .StripeElement {
                display: block;
                border: #c1e4fe solid 1px;
                box-shadow: none;
                box-sizing: border-box;
                padding-left: 12px;
                color: #2b5672;
                font-size: 14px;
                outline: none;
                border-radius: 5px;
                height: 36px;
                outline: 0;
            }

            .cc-icons-container {
                display: flex;
                margin: 0 0 15px 0;

                .cc-icon {
                    height: 25px;
                    width: 37px;
                    padding-right: 9px;
                    background-repeat: no-repeat;
                    box-sizing: content-box;
                }
            }
        }
    }

    .card-plan {
        .auto-renew-container {
            display: flex;
            align-items: center;
            p {
                margin: 0 10px 0 0;
            }

        }
    }


}