@import "../../../stylesheets/variables";

.subpage-pages {

    .container-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        cursor: pointer;

        svg {
            path {
                fill: #ced4da;
                transition: fill 0.35s;
            }
        }
    }

    .container-icon:hover {

        svg {
            path {
                fill: #3899eb;
            }
        }
    }

    .rules-container {
        .rules {
            .form-group-and-or {
                margin: 10px 0;
            }
        }

        .btn {
            margin-top: 15px;
        }
    }
}