@import "../../stylesheets/variables";

.main-spinner {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    .spinner-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .spinner-container-inner {
            width: 54px;
            height: 54px;
            animation: rotation 2s linear infinite;
            position: relative;

            .circle1 {
                animation: rotation 666ms ease-in-out infinite;
                stroke: #c1e4fe;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                fill: none;
                stroke-linecap: round;
            }

            .circle2 {
                stroke: #2b81cb;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                fill: none;
                stroke-linecap: round;
            }
        }

        p {
            margin: 15px 0 0 0;
            font-size: 12px;
            text-align: center;
            text-transform: uppercase;
            font-family: $helvetica-500;
        }
    }
}

@keyframes rotation {
    100% {
        transform: rotate(360deg);
    }
}