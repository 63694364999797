.sidebar-signup {

    .container-inputs {
        .container-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            cursor: pointer;

            svg {
                path {
                    fill: #ced4da;
                    transition: fill 0.35s;
                }
            }
        }

        .container-icon:hover {

            svg {
                path {
                    fill: #3899eb;
                }
            }
        }

        .form-inputs {
            margin: 0 0 10px 0;
        }
    }
}