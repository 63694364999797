.sidebar-html-controls {
    display: flex;
    flex-direction: column;

    .sidebar-header {
        flex: 0;
    }

    .sidebar-section-html {
        flex: 1;
        margin: 0 !important;

        .ae {
            height: 100%;
            border: 1px solid #e5e5e5;
        }
    }
}