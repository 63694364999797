.main-targeting {
    .subpage {
        .page-inner {
            .btn-container {
                .btn {
                    margin: 0 10px 0 0;
                }
            }

            .help-container {
                p {
                    color: #808080;
                }
            }
        }
    }
}