.sidebar-image {

    .image-btn-container {
        display: flex;
        justify-content: center;
        margin: 10px 0;

        .btn {
            margin: 0 5px;
        }
    }
}