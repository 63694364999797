.hidden {
    display: none !important;
}


.animation-fadein {
    animation: animation-fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@keyframes animation-fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


.animation-slidein {
    animation: animation-slide-in 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes animation-slide-in {
    0% {
        transform: translateY(-1000px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}


.animation-scalein {
    animation: animation-scale-in 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@keyframes animation-scale-in {
    0% {
        transform: scale(0);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}


