.page-no-apps {
    background-color: #fff;

    .no-websites-col {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .no-websites-container {
        margin-left: 45px;

        .btn-container {
            margin: 25px 0 0 0;
        }
    }
}