.gradient-control {
    position: relative;

    .gpw {
        padding-left: 0;

        .popover {
            top: 50px;
        }
    }
}