@import "../../../stylesheets/variables";

.subpage-schedule {

    .scheduling-container {

        .btn-add-container {
            margin-top: 15px;
        }

        .schedules-container {

            p.heading {
                color: #162c3c;
                //font-family: $helvetica-500;
            }
            .schedule-row {
                .form-check-input {
                    margin-top: 8px;
                }

                .icon-container {
                    cursor: pointer;
                    margin-top: 4px;

                    svg {
                        path {
                            fill: #808080;
                        }
                    }
                }

                .icon-container:hover {
                    svg {
                        path {
                            fill: #3899eb;
                        }
                    }
                }
            }
        }
    }
}