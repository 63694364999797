.apps-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 45px;

    .app-container {
        width: 100%;

        .app-container-inner {
            background-size: cover;
            background-repeat: no-repeat;
            border: 1px solid #e5e9eb;
            border-radius: 10px;
            cursor: pointer;
        }

        .app-container-inner::before {
            content: "";
            padding-bottom: 100%;
            display: inline-block;
            vertical-align: top;
        }

        .info-container {
            text-align: center;
            color: #4c4c4c;
            margin: 10px 0 0 0;

            p.name {
                font-size: 20px;
            }
        }
    }


}