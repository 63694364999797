@import "../../stylesheets/variables";

.card {
    //border-radius: 0 !important;
    box-shadow: 0 3px 3px 0 rgba(59,65,94,.07), 0 5px 5px 0 rgba(0,0,0,.01);
    margin-bottom: 25px;
    align-items: flex-start;
    background-color: #fff;
    border: 1px solid #d9d9d9;

    .card-header {
        background-color: #fff;
        //border-bottom: 1px solid #e3e8ee;
        border-bottom: 0;
        width: 100%;
        border-top-left-radius: 8px !important;
        border-top-right-radius: 8px !important;

        p {
            margin: 5px 0;
            padding: 0;
            font-size: 16px;
            //font-weight: 600;
            font-family: $helvetica-600;
            //color: #515355;
            background-color: transparent;
            border: 0;
        }
    }

    .card-body {
        padding: 15px;
        width: 100%;

        p {
            color: #818384;
        }

        .table {
            margin: 0;

            thead {
                tr {
                    th {
                        border-top: 0;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        line-height: 1;
                        vertical-align: middle;
                    }
                }
            }
        }


    }
}