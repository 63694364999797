.page-settings-developer {

    .card-api-keys {

        .api-keys-container {
            margin: 0 0 25px 0;

            .api-key-container {
                margin: 0 0 25px 0;

                .btn-container {
                    .btn {
                        margin-right: 10px;
                    }
                }
            }
        }
    }

}