@import "variables";
//@import "semantic/semantic";

body {
    font-family: 'Open Sans', sans-serif;
    //font-family: $helvetica-400;
    //display: flex;
    //flex-direction: column;
    //height: 100vh;
    font-size: 14px;
    color: #323d48;
    //color: #757576;
    background-color: #fff;
}

html {
    body {
        color: #323d48;
    }
}

h1 {
    //font-family: $helvetica-600;
    //font-size: 36px;
    //line-height: 48px;
    //margin: 28px 0 24px;
    //font-weight: 400;
}



.hidden {
    display: none !important;
}

.underline {
    text-decoration: underline;
}

.form-control:focus {
    box-shadow: none;
}

input {
    border: 1px solid #d7d7d7;
    color: rgba(6, 56, 85, 0.8);
    padding: 2px 5px;
    font-size: 14px;
    transition: border-color 0.3s;
}

input:focus {
    outline: none;
    border-color: #01bcd4;
}

#root {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    //overflow: hidden;
}

.app {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    //min-height: 100vh;
    //height: 100%;
    min-height: 0;

    .main {
        position: relative;
        display: flex;
        flex: 1;
        min-height: 0;
        overflow-x: hidden;
    }
}

*:focus {
    outline:none !important;
    box-shadow: none !important;
}

.app {
    .btn {
        outline: 0;
        border-radius: 22px;
        padding: 6px 16px;
        cursor: pointer;
        min-width: 90px;
        font-size: 14px;
        justify-content: center;
        align-items: center;
        line-height: 1;
        display: inline-flex;

        .btn-inner {
            display: flex;
            align-items: center;
            height: 100%;

            i {
                margin-right: 5px;
                font-size: 16px;
            }
        }
    }

    .btn:disabled {
        cursor: not-allowed;
    }

    .btn:focus {
        outline: 0;
        box-shadow: none;
    }

    .btn-link {
        background: none;
        border: none;
        cursor: pointer;
    }

    .btn-large {
        padding: 10px 20px;
    }

    .btn-icon-round, .btn-icon-round:disabled {
        outline: 0;
        border-radius: 24px;
        cursor: pointer;
        padding: 0;
        width: 48px;
        height: 48px;
        font-size: 14px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
        background-color: transparent;
        border: 1px solid #c1e4fe;
        color: #3899eb;
    }

    .btn-icon-round:hover {
        background-color: #eaf7ff;
    }

    .btn-icon-round-danger, .btn-icon-round-danger:disabled {
        border-color: #dc3545;
        color: #dc3545;
    }

    .btn-icon-round-danger:hover {
        background-color: #f8d7da;
    }

    .btn-primary, .btn-primary.disabled {
        color: #fff;
        background-color: #3899ec;
        border: 1px solid #3899ec;
    }

    .btn-primary:hover,
    .btn-primary:active,
    .btn-primary:focus {
        color: #fff;
        background-color: #4eb7f5 !important;
        border-color: #4eb7f5 !important;
    }

    .btn-white, .btn-white.disabled {
        color: #3899eb;
        background-color: #fff;
        border: 1px solid #fff;
    }

    .btn-white:hover,
    .btn-white:active,
    .btn-white:focus {
        color: #fff;
        background-color: #4eb7f4 !important;
        border-color: #4eb7f4 !important;
    }

    .btn-light-blue-outline {
        background-color: transparent;
        border: 1px solid #c1e4fe;
        color: #162c3c;
    }

    .btn-light-blue-outline:hover,
    .btn-light-blue-outline:active,
    .btn-light-blue-outline:focus {
        background-color: #eaf7ff !important;
        border: 1px solid #c1e4fe !important;
    }

    .btn-primary-outline {
        border: 1px solid #3899ec;
        color: #3899ec;
        background-color: transparent;
    }

    .btn-primary-outline:hover {
        border: 1px solid #3899ec;
        color: #fff;
        background-color: #3899ec;
    }

    .btn-secondary-outline {
        border: 1px solid #6d6d6d;
        color: #6d6d6d;
        background-color: transparent;
    }

    .btn-secondary-outline:hover {
        border: 1px solid #6d6d6d;
        color: #fff;
        background-color: #6d6d6d;
    }

    .btn-secondary {
        color: #6d6d6d;
        background-color: #fff;
        border: 1px solid #c0ccda;
    }

    .btn-secondary:hover,
    .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active {
        color: #6d6d6d;
        background-color: #fff;
    }

    .btn-secondary:disabled {
        color: #4e566d;
        background: #fff;
    }

    .btn-purple {
        color: #fff;
        background-color: #aa4dc7;
        border-color: #aa4dc7;
    }

    .btn-purple:hover {
        color: #fff;
        background-color: #cc68ed;
        border-color: #cc68ed;
    }

    .btn-purple-outline {
        color: #aa4dc7;
        background-color: transparent;
        border-color: #aa4dc7;
    }

    .btn-purple-outline:hover {
        color: #fff;
        background-color: #aa4dc7;
        border-color: #aa4dc7;
    }

    .btn-red {
        color: #fff;
        background-color: #e7736d;
        border-color: #e7736d;
    }

    .btn-red:hover {
        color: #fff;
        background-color: #f98a8a;
        border-color: #f98a8a;
    }


    .button-spinner {
        margin-left: 5px;
    }

    .btn-icon {
        outline: 0;
        border: 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
            font-size: 20px;
            color: #515355;
            transition: all 0.25s;
        }

        .caption {
            margin-left: 5px;
            transition: all 0.25s;
            color: #515355;
        }
    }

    .btn-icon:hover {
        transition: all 0.25s;

        i, .caption {
            color: #01bcd4;
        }
    }

    .btn-icon:focus {
        outline: 0;
        box-shadow: none;
    }


    form {
        .form-group {
            label {
                //font-weight: 600;
                //font-family: $helvetica-500;
                font-weight: 400;
            }

            .form-text-error {
                color: #9d2146;
            }
        }
    }
}