.sidebar-blocks {

    .sidebar-section-blocks {

        .blocks-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-wrap: wrap;
            margin: 10px 0;

            .block-container {
                display: flex;
                flex-direction: column;
                margin: 5px;
                cursor: pointer;
                height: 65px;
                transition: background-color 300ms ease 0s;
                border: 1px solid rgb(230, 235, 238);

                .block {
                    width: 100%;
                    height: 100%;
                    background-color: #fff;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    i {
                        font-size: 22px;
                        color: #808080;
                    }

                    svg {
                        height: 16px;
                        width: auto;

                    }

                    p {
                        margin: 10px 0 0 0;
                        color: rgb(132, 146, 166);
                        font-size: 13px;
                        text-align: center;
                        line-height: 1;
                    }
                }



            }

            .block-container:hover {
                    border-color: #3899ec;
            }
        }
    }
}