.screen-headers-container {
    display: grid;
}

.screen-header {
    background-color: #d7d7d7;
    border-radius: 8px;
    margin: 0 10px 10px 10px;
    position: relative;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
    height: 34px;
    display: flex;

    p {
        margin: 0 30px 0 30px;
        width: 100%;
        line-height: 1.5;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: center;
    }

    .icon-container {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        padding: 0 10px 0 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        i {
            color: #808080;
        }
    }
}