.admin-controls {
    flex: 0 0 56px;
    display: flex;
    background-color: #fff;
    border-left: 1px solid #eff0f1;
    border-top: 1px solid #e6ebee;
    z-index: 1010;

    .tools-container {
        display: flex;
        justify-content: space-between;
        height: 100%;
        flex: 1;

        .tool-group {
            display: flex;
            align-items: center;
            border-left: 1px solid #e6ebee;
            margin: 5px 0;
            padding: 0 15px;

            .tool {

            }

            .tool-campaign-active-switch {
                display: flex;
                align-items: center;

                p {
                    margin: 0;
                    line-height: 1;
                    margin-right: 10px;
                }
            }

            .tool-campaign-name {
                display: flex;
                align-items: center;

                input.form-control {

                }
            }
        }

        .tool-btn-group {
            display: flex;
            align-items: center;
            border-left: 1px solid #e6ebee;
            margin: 5px 0;
            padding: 0 15px;

            .btn-tool {
                i {
                    color: #c0ccd9;
                }
            }

            .btn-tool.active {
                i {
                    color: #449fed;
                }
            }
        }

        .tool-btn-group-save-template {
            select {
                margin-right: 15px;
            }
        }
    }

    .tools-container-left {

    }

    .tools-container-right {
        display: flex;
        justify-content: flex-end;
    }
}