.sidebar-style {

    .sidebar-section-size-presets {

        .size-containers {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            .size-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 5px;
                cursor: pointer;

                .size-container-inner {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid rgb(230, 235, 238);
                    color: rgb(192, 204, 218);
                    transition: border-color 300ms ease 0s;
                    height: 65px;
                    width: 86px;

                    .preview-size {
                        background-color: #e9e9e9;
                        transition: background-color 300ms ease 0s;
                    }
                }

                p {
                    color: #162d3d;
                    font-size: 13px;
                    margin: 5px 0 0 0;
                }
            }

            .size-container:hover {
                .size-container-inner {
                    border-color: #7fccf7;
                }
            }

            .size-container.active {
                .size-container-inner {
                    border-color: #7fccf7;

                    .preview-size {
                        background-color: #7fccf7;
                    }
                }
            }

            .size-container-small {
                .size-container-inner {
                    .preview-size {
                        width: 24px;
                        height: 18px;
                    }
                }
            }

            .size-container-medium {
                .size-container-inner {
                    .preview-size {
                        width: 40px;
                        height: 30px;
                    }
                }
            }

            .size-container-large {
                .size-container-inner {
                    .preview-size {
                        width: 60px;
                        height: 45px;
                    }
                }
            }

            .size-container-fullWidth {
                .size-container-inner {
                    align-items: flex-end;

                    .preview-size {
                        width: 100%;
                        height: 20px;
                    }
                }
            }

            .size-container-fullHeight {
                .size-container-inner {
                    justify-content: flex-end;

                    .preview-size {
                        width: 20px;
                        height: 100%;
                    }
                }
            }

            .size-container-custom {
                .size-container-inner {
                    align-items: flex-end;

                    .preview-size {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

        }
    }

    .image-btn-container {
        display: flex;
        justify-content: center;
        margin: 10px 0;

        .btn {
            margin: 0 5px;
        }
    }
}