#header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    z-index: 1020;
    background-color: #fff;
    box-shadow: 0 1px 5px 0 rgba(41, 85, 115, .21);
    font-weight: 400;
    padding: 0 25px;
    align-items: center;

    .left {

    }

    .logo-container {
        width: 100%;
        height: 30px;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            height: 100%;

            .logo {
                height: 100%;
                width: auto;
            }
        }
    }

    .right {
        display: flex;
        justify-content: flex-end;

        .dropdown {
            .dropdown-item {
                color: #3b3b3b;
                display: flex;
                align-items: center;
                padding: 10px 25px;

                .material-icons {
                    font-size: 20px;
                    margin-right: 5px;
                }
            }
        }

    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;

            a {
                padding: 25px 15px;
                line-height: 1;
                color: #3b3b3b;
            }

            a:hover {
                color: #000;
            }
        }

        li:hover {
            //background-color: #fafafa;
        }
    }

    .dropdown {
        color: #3b3b3b;
        .menu {
            right: 0;
            left: auto;

            .item {
                color: #3b3b3b;

                a {
                    padding: 0;
                }
            }
        }
    }
}