.page-websites-index {

    h1 {
        margin-bottom: 0;
    }

    .btn-add-website {
        margin: 15px 0 25px 0;
    }

}