.purchase-modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.35);
    z-index: 1030;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;


    .purchase-modal-outer {
        background-color: #fff;
        overflow: scroll;
        max-height: 100%;
        border-radius: 8px;
        width: 80%;

        .close-btn-container {
            position: absolute;
            top: 15px;
            right: 15px;
            cursor: pointer;

            i {
                color: #fff;
                font-size: 32px;
            }
        }

        .close-btn-container:hover {
            i {
                color: #000;
            }
        }

        .purchase-modal-inner {
            width: 100%;

            h1 {
                text-align: center;
                margin: 25px 0 10px 0;
            }

            h2 {
                font-size: 16px;
                text-align: center;
                margin: 0 0 15px 0;
                line-height: 24px;
                font-weight: 400;
            }

            .btn-back-container {
                margin: 0 0 25px 0;
            }

            .cc-icons-container {
                display: flex;
                margin: 0 0 15px 0;

                .cc-icon {
                    height: 25px;
                    width: 37px;
                    padding-right: 9px;
                    background-repeat: no-repeat;
                    box-sizing: content-box;
                }
            }

            .payment-form-container {

                label {
                    font-weight: 300;
                    font-size: 14px;
                    margin: 0;
                }

                input, select, .StripeElement {
                    display: block;
                    border: #c1e4fe solid 1px;
                    box-shadow: none;
                    box-sizing: border-box;
                    padding-left: 12px;
                    color: #2b5672;
                    font-size: 14px;
                    outline: none;
                    border-radius: 5px;
                    height: 36px;
                    outline: 0;
                }

                .purchase-summary {
                    font-weight: 400;
                    display: flex;
                    flex-direction: column;
                    margin: 0 0 15px 0;

                    .choice-container {
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        grid-gap: 15px;

                        .choice {
                            border: 1px solid #dfdfdf;
                            border-radius: 4px;
                            padding: 10px 15px;
                            cursor: pointer;

                            p {
                                color: #a1aec1;
                                margin: 0;
                            }

                            p.frequency {
                                font-size: 20px;
                                font-weight: 500;
                            }

                            p.price {
                                font-size: 30px;
                                font-weight: 600;
                                color: #09aaed;
                            }

                            p.per,
                            p.billing-frequency {
                                font-size: 14px;
                            }
                        }

                        .choice.selected {
                            border-color: #09aaed;
                        }
                    }

                    .special-offer {
                        border: 1px solid #3899eb;
                        border-radius: 4px;
                        padding: 6px 10px;
                        margin: 0 0 15px 0;
                        align-self: center;

                        p {
                            color: #3899eb;
                            margin: 0;
                            line-height: 1;
                            font-size: 13px;
                        }
                    }

                    .summary-row {
                        display: flex;
                        justify-content: space-between;
                        margin: 0 0 14px 0;
                    }

                    .plan-savings-row {
                        color: #60bc57;
                    }

                    .discount-row {
                        color: #60bc57;
                    }

                    .subtotal-row {
                        padding-top: 16px;
                    }

                    .total-row {
                        font-weight: 600;
                        border-top: 1px solid #dfe5eb;
                        line-height: 1;
                        padding: 16px 0;

                    }
                }

                .btn-container {
                    display: flex;
                    justify-content: center;

                    .btn-submit {
                        //height: 42px;
                        width: 200px;
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        margin-top: 15px;

                        svg {
                            height: 14px;
                            width: 11px;
                            fill: #fff;
                            margin-right: 7px;
                        }
                    }
                }

                .terms-container {
                    margin: 20px 0 0 0;

                    .terms-row {
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        svg {
                            height: 20px;
                            margin-right: 5px;
                        }

                        p {
                            margin: 0;
                            font-size: 14px;
                            color: #162d3d;
                        }

                    }
                }
            }
        }
    }
}