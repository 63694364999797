.sidebar-custom-code {
    display: flex;
    flex-direction: column;

    .code-container {
        flex: 1;

        .ae {
            height: 100%;
            border: 1px solid #e5e5e5;
        }
    }
}