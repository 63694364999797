.page-settings-general {

    .card-profile {
        .row {
            margin-bottom: 25px;
        }

        label {
            font-weight: 500;
        }

        .img-profile-container {
            width: 75px;
            height: 75px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            .img-profile {
                //border-radius: 50%;
                width: 100%;
                height: auto;
            }

            .pen {
                opacity: 0;
                margin: auto;
                position: absolute;
                top: 0; left: 0; bottom: 0; right: 0;
                transition: opacity 0.35s;

                path {
                    fill: #f0f4f7;
                }

            }
        }

        .img-profile-container-editable {
            cursor: pointer;
        }

        .img-profile-container-editable:hover {

            .pen {
                opacity: 1;
            }
        }

        .managed-by-google-container {

            p {
                margin: 25px 0 10px 0;
            }
        }

    }
}