@import "../../stylesheets/variables";


.page-app-overview {

    .header-container {
        border-bottom: 1px solid #dfdfdf;
        margin: 10px 0 25px 0;
        padding: 10px 0;
        justify-content: flex-start !important;

        .app-icon-container {
            width: 75px;
            height: 75px;
            border: 1px solid #dfdfdf;
            border-radius: 8px;
            margin: 0 15px 0 0;
            background-size: cover;
            background-repeat: no-repeat;
        }

        .text-container {
            h1 {
                margin: 0;
            }
        }
    }

    .app-container {
        .card {
            .card-body {
                .info-cols {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-gap: 15px;

                    .info-col {
                        .dropdown {
                            .dropdown-toggle {
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .projects-container {
        .card {
            .card-body {
                padding-top: 0;
                .btn-add-project-container {
                    display: flex;
                    margin: 0 0 25px 0;
                }

                .projects-list {
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-gap: 15px;

                    .project-container {
                        //border-bottom: 1px solid #dfdfdf;
                        background-color: #fafafa;
                        border-radius: 8px;
                        padding: 20px;
                        border: 1px solid #d6d6d6;

                        p.heading {
                            font-size: 16px;
                            color: #212529;
                            font-family: $helvetica-600;
                        }

                        .project-cols {
                            display: grid;
                            grid-template-columns: 1fr 1fr;
                            grid-gap: 15px;

                            .project-col {
                                .dropdown {
                                    .dropdown-toggle {
                                        padding: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }
}