body.welcome-login {
    .login-page {
        height: 100vh;
        width: 100%;
        background-color: #0e1e25;

        .content {
            height: 100vh;
            display: flex;
            justify-content: center;

            h1 {
                text-align: center;
                color: #fff;
                font-weight: 300;
                margin: 100px 0 35px 0;
            }

            .card-signup {
                width: 100%;
                align-self: center;

                .have-account-container {
                    p {
                        margin: 10px 0 0 0;
                        font-size: 14px;
                        color: #333;
                    }
                }
            }
        }
    }
}