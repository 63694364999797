.sidebar-download {
    .btn-download-container {
        display: flex;
        justify-content: center;
    }

    .purchase-plan-info {
        text-align: center;

        .btn-container {
            display: flex;
            justify-content: center;
        }
    }
}