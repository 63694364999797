.page-settings-websites {


    .table-container {
        background-color: #fff;
        min-height: calc(100vh - 342px);
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        .default-table {
            border-collapse: collapse;
            border-spacing: 0;
            table-layout: fixed;
            background-color: #fff;
            width: 100%;

            thead {
                tr {
                    th {
                        font-size: 14px;
                        line-height: 18px;
                        padding: 14px 12px 14px 12px;
                        background-color: #daeffe;
                        height: 24px;
                        color: #162d3d;
                        box-shadow: inset 0 1px 0 0 #c1e4fe, inset 0 -1px 0 0 #c1e4fe;
                        border: 0;

                    }

                    th:first-of-type {
                        padding-left: 30px;
                    }
                }
            }

            tbody {
                cursor: pointer;

                tr {
                    td {
                        padding: 18px 12px;
                        box-shadow: inset 0 -1px 0 0 #dfe5eb;
                        border: 0;

                        .website-actions {
                            display: flex;
                            align-items: center;

                            .table-btn-container {
                                display: flex;

                                .table-btn {
                                    display: flex;
                                    align-items: center;
                                    margin-right: 10px;

                                    i {
                                        color: #dfe5eb;
                                    }
                                }

                                .table-btn:hover {
                                    i {
                                        color: #3899eb;
                                    }
                                }
                            }
                        }
                    }

                    td:first-of-type {
                        padding-left: 30px;
                    }

                }


            }
        }
    }
}