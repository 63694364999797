.page-visitor-data {

    .card-data-table {
        overflow: hidden;

        .card-body {
            padding-top: 0;
            padding-left: 0;
            padding-right: 0;

            .no-data-container {
                display: flex;
                flex-direction: column;
                align-items: center;

                img {
                    width: 350px;
                    margin: 0 0 25px 0;
                }
            }
        }
    }
}